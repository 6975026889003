<template>
    <div >
        <!-- 以太网芯片 -->
        <div class="imgContent" v-if="categoryId==4">
          <!-- 手机端 -->
          <div v-if="smallScreen">
            <img style="width:100%; height:100%;" src="@/assets/image/lineUp/4_.jpg" alt="">
            <a class="unit4_ unit4_397" href="https://www.wch.cn/products/CH397.html" target="_blank" title="CH397"></a>
            <a class="unit4_ unit4_9121" href="https://www.wch.cn/products/CH9121.html" target="_blank" title="CH9121"></a>
            <a class="unit4_ unit4_9120" href="https://www.wch.cn/products/CH9120.html" target="_blank" title="CH9120"></a>
            <a class="unit4_ unit4_395" href="https://www.wch.cn/products/CH395.html" target="_blank" title="CH395"></a>
            <a class="unit4_ unit4_394" href="https://www.wch.cn/products/CH394.html" target="_blank" title="CH394"></a>
            <a class="unit4_ unit4_392" href="https://www.wch.cn/products/CH392.html" target="_blank" title="CH392"></a>
            <a class="unit4_ unit4_390" href="https://www.wch.cn/products/CH390.html" target="_blank" title="CH390"></a>
            <a class="unit4_ unit4_182" href="https://www.wch.cn/products/CH182.html" target="_blank" title="CH182"></a>
          </div>
          <!-- 非手机端 -->
          <div v-else>
            <img style="width:100%; height:100%;" src="@/assets/image/lineUp/4.jpg" alt="">
            <a class="unit4 unit4_397" href="https://www.wch.cn/products/CH397.html" target="_blank" title="CH397">CH397</a>
            <a class="unit4 unit4_9121" href="https://www.wch.cn/products/CH9121.html" target="_blank" title="CH9121">CH9121</a>
            <a class="unit4 unit4_9120" href="https://www.wch.cn/products/CH9120.html" target="_blank" title="CH9120">CH9120</a>
            <a class="unit4 unit4_395" href="https://www.wch.cn/products/CH395.html" target="_blank" title="CH395">CH395</a>
            <a class="unit4 unit4_394" href="https://www.wch.cn/products/CH394.html" target="_blank" title="CH394">CH394</a>
            <a class="unit4 unit4_392" href="https://www.wch.cn/products/CH392.html" target="_blank" title="CH392">CH392</a>
            <a class="unit4 unit4_390" href="https://www.wch.cn/products/CH390.html" target="_blank" title="CH390">CH390</a>
            <a class="unit4 unit4_182" href="https://www.wch.cn/products/CH182.html" target="_blank" title="CH182">CH182</a>
          </div>
        </div>
        <!-- 蓝牙芯片 -->
        <div class="imgContent" v-else-if="categoryId==63">
            <img style="width:100%; height:100%;" src="@/assets/image/lineUp/63.png" alt="">
            <a class="unit63 unit63_585" href="https://www.wch.cn/products/CH585.html" target="_blank" title="CH585"></a>
            <a class="unit63 unit63_584" href="https://www.wch.cn/products/CH585.html" target="_blank" title="CH584"></a>
            <a class="unit63 unit63_592" href="https://www.wch.cn/products/CH592.html" target="_blank" title="CH592"></a>
            <a class="unit63 unit63_591" href="https://www.wch.cn/products/CH592.html" target="_blank" title="CH591"></a>
            <a class="unit63 unit63_582" href="https://www.wch.cn/products/CH583.html" target="_blank" title="CH582/3"></a>
            <a class="unit63 unit63_573" href="https://www.wch.cn/products/CH573.html" target="_blank" title="CH573"></a>
            <a class="unit63 unit63_571" href="https://www.wch.cn/products/CH573.html" target="_blank" title="CH571"></a>
            <a class="unit63 unit63_32V208" href="https://www.wch.cn/products/CH32V208.html" target="_blank" title="CH32V208"></a>
            <a class="unit63 unit63_9142" href="https://www.wch.cn/products/CH9142.html" target="_blank" title="CH9142"></a>
            <a class="unit63 unit63_9143" href="https://www.wch.cn/products/CH9143.html" target="_blank" title="CH9143"></a>
            <a class="unit63 unit63_9141" href="https://www.wch.cn/products/CH9141.html" target="_blank" title="CH9141"></a>
            <a class="unit63 unit63_9140" href="https://www.wch.cn/products/CH9140.html" target="_blank" title="CH9140"></a>
            <a class="unit63 unit63_579" href="https://www.wch.cn/products/CH579.html" target="_blank" title="CH579"></a>
            <a class="unit63 unit63_578" href="https://www.wch.cn/products/CH578.html" target="_blank" title="CH578"></a>
            <a class="unit63 unit63_577" href="https://www.wch.cn/products/CH578.html" target="_blank" title="CH577"></a>
            <a class="unit63 unit63_32F208" href="https://www.wch.cn/products/CH32F208.html" target="_blank" title="CH32F208"></a>
        </div>
        <!-- Cortex-M通用系列 -->
        <div class="imgContent" v-else-if="categoryId==71">
            <img style="width:100%; height:100%;" src="@/assets/image/lineUp/71.png" alt="">
            <a class="unit71 unit71_32F203" style="top:62%; left:9.8%;" href="https://www.wch.cn/products/CH32F203C8.html?" target="_blank" title="CH32F203"></a>
            <a class="unit71 unit71_32F203" style="top:44.5%; left:25.2%;" href="https://www.wch.cn/products/CH32F203.html" target="_blank" title="CH32F203"></a>
            <a class="unit71 unit71_32F203" style="top:54.1%; left:25.2%;" href="https://www.wch.cn/products/CH32F203C8.html?" target="_blank" title="CH32F203"></a>
            <a class="unit71 unit71_32F103" style="top:62%; left:25.2%;" href="https://www.wch.cn/products/CH32F103.html" target="_blank" title="CH32F103"></a>
            <a class="unit71 unit71_32F203" style="top:71.5%; left:25.2%;" href="https://www.wch.cn/products/CH32F203C8.html?" target="_blank" title="CH32F203"></a>
            <a class="unit71 unit71_32F103" style="top:79.1%; left:25.2%;" href="https://www.wch.cn/products/CH32F103.html" target="_blank" title="CH32F103"></a>
            <a class="unit71 unit71_32F203" style="top:54.1%; left:40.2%;" href="https://www.wch.cn/products/CH32F203C8.html?" target="_blank" title="CH32F203"></a>
            <a class="unit71 unit71_32F103" style="top:62%; left:40.2%;" href="https://www.wch.cn/products/CH32F103.html" target="_blank" title="CH32F103"></a>
            <a class="unit71 unit71_32F203" style="top:20.2%; left:55.6%;" href="https://www.wch.cn/products/CH32F203.html" target="_blank" title="CH32F203"></a>
            <a class="unit71 unit71_32F208" style="top:31%; left:55.6%; height:6%;" href="https://www.wch.cn/products/CH32F208.html" target="_blank" title="CH32F208"></a>
            <a class="unit71 unit71_32F205" style="top:39.6%; left:55.6%; height:5.6%;" href="https://www.wch.cn/products/CH32F207.html" target="_blank" title="CH32F205"></a>
            <a class="unit71 unit71_32F203" style="top:45.6%; left:55.6%; height:5.6%;" href="https://www.wch.cn/products/CH32F203.html" target="_blank" title="CH32F203"></a>
            <a class="unit71 unit71_32F103" style="top:62%; left:55.6%;" href="https://www.wch.cn/products/CH32F103.html" target="_blank" title="CH32F103"></a>
            <a class="unit71 unit71_32F208" style="top:44.7%; left:71.1%;" href="https://www.wch.cn/products/CH32F208.html" target="_blank" title="CH32F208"></a>
            <a class="unit71 unit71_32F207" style="top:12.9%; left:87.5%; height:6.5%;" href="https://www.wch.cn/products/CH32F207.html" target="_blank" title="CH32F207"></a>
            <a class="unit71 unit71_32F203" style="top:20.4%; left:87.5%; height:6.5%;" href="https://www.wch.cn/products/CH32F203.html" target="_blank" title="CH32F203"></a>
        </div>
        <!-- MCU -->
        <div class="imgContent" v-else-if="categoryId==5">
            <img style="width:100%; height:100%;" src="@/assets/image/lineUp/5.png" alt="">
            <a class="unit68 unit68_32V003"  href="https://www.wch.cn/products/CH32V003.html" target="_blank" title="CH32V003"></a>
            <a class="unit68 unit68_641"  href="https://www.wch.cn/products/CH641.html" target="_blank" title="CH641"></a>
            <a class="unit68 unit68_32M007"  href="https://www.wch.cn/products/CH32M007.html" target="_blank" title="CH32M007"></a>
            <a class="unit68 unit68_32V007"  href="https://www.wch.cn/products/CH32V007.html" target="_blank" title="CH32V007"></a>
            <a class="unit68 unit68_32V006"  href="https://www.wch.cn/products/CH32V006.html" target="_blank" title="CH32V006"></a>
            <a class="unit68 unit68_32V005"  href="https://www.wch.cn/products/CH32V005.html" target="_blank" title="CH32V005"></a>
            <a class="unit68 unit68_32V002"  href="https://www.wch.cn/products/CH32V002.html" target="_blank" title="CH32V002"></a>
            <a class="unit68 unit68_573"  href="https://www.wch.cn/products/CH573.html" target="_blank" title="CH573"></a>
            <a class="unit68 unit68_571"  href="https://www.wch.cn/products/CH573.html" target="_blank" title="CH571"></a>
            <a class="unit68 unit68_32V103"  href="https://www.wch.cn/products/CH32V103.html" target="_blank" title="CH32V103"></a>
            <a class="unit68 unit68_32M030"  href="https://www.wch.cn/products/CH32M030.html" target="_blank" title="CH32M030"></a>
            <a class="unit68 unit68_565"  href="https://www.wch.cn/products/CH569.html" target="_blank" title="CH565/9"></a>
            <a class="unit68 unit68_585"  href="https://www.wch.cn/products/CH585.html" target="_blank" title="CH585/4"></a>
            <a class="unit68 unit68_582"  href="https://www.wch.cn/products/CH583.html" target="_blank" title="CH582/3"></a>
            <a class="unit68 unit68_32V203"  href="https://www.wch.cn/products/CH32V203.html" target="_blank" title="CH32V203"></a>
            <a class="unit68 unit68_592"  href="https://www.wch.cn/products/CH592.html" target="_blank" title="CH592"></a>
            <a class="unit68 unit68_591"  href="https://www.wch.cn/products/CH592.html" target="_blank" title="CH591"></a>
            <a class="unit68 unit68_32V208"  href="https://www.wch.cn/products/CH32V208.html" target="_blank" title="CH32V208"></a>
            <a class="unit68 unit68_32L103"  href="https://www.wch.cn/products/CH32L103.html" target="_blank" title="CH32L103"></a>
            <a class="unit68 unit68_32X035"  href="https://www.wch.cn/products/CH32X035.html" target="_blank" title="CH32X035"></a>
            <a class="unit68 unit68_645"  href="https://www.wch.cn/products/CH645.html" target="_blank" title="CH645"></a>
            <a class="unit68 unit68_643"  href="https://www.wch.cn/products/CH643.html" target="_blank" title="CH643"></a>
            <a class="unit68 unit68_32V303"  href="https://www.wch.cn/products/CH32V303.html" target="_blank" title="CH32V303"></a>
            <a class="unit68 unit68_32V317"  href="https://www.wch.cn/products/CH32V317.html" target="_blank" title="CH32V317"></a>
            <a class="unit68 unit68_32V307"  href="https://www.wch.cn/products/CH32V307.html" target="_blank" title="CH32V307"></a>
            <a class="unit68 unit68_32V305"  href="https://www.wch.cn/products/CH32V307.html" target="_blank" title="CH32V305"></a>
            <a class="unit68 unit68_564"  href="https://www.wch.cn/products/CH564.html" target="_blank" title="CH564"></a>
            <a class="unit68 unit68_579"  href="https://www.wch.cn/products/CH579.html" target="_blank" title="CH579"></a>
            <a class="unit68 unit68_578"  href="https://www.wch.cn/products/CH578_7.html" target="_blank" title="CH578"></a>
            <a class="unit68 unit68_577"  href="https://www.wch.cn/products/CH578_7.html" target="_blank" title="CH577"></a>
            <a class="unit68 unit68_32F207"  href="https://www.wch.cn/products/CH32F207.html" target="_blank" title="CH32F207"></a>
            <a class="unit68 unit68_32F205"  href="https://www.wch.cn/products/CH32F207.html" target="_blank" title="CH32F205"></a>
            <a class="unit68 unit68_32F103"  href="https://www.wch.cn/products/CH32F103.html" target="_blank" title="CH32F103"></a>
            <a class="unit68 unit68_32F203"  href="https://www.wch.cn/products/CH32F203.html" target="_blank" title="CH32F203"></a>
            <a class="unit68 unit68_32F208"  href="https://www.wch.cn/products/CH32F208.html" target="_blank" title="CH32F208"></a>
        </div>
        <!-- 青稞RISC-V通用系列 -->
        <div class="imgContent" v-else-if="categoryId==70">
            <img style="width:100%; height:100%;" src="@/assets/image/lineUp/70.png" alt="">
            <a class="unit70 unit70_32V003"  href="https://www.wch.cn/products/CH32V003.html" target="_blank" title="CH32V003"></a>
            <a class="unit70 unit70_32V002"  href="https://www.wch.cn/products/CH32V002.html" target="_blank" title="CH32V002"></a>
            <a class="unit70 unit70_32V006"  href="https://www.wch.cn/products/CH32V006.html" target="_blank" title="CH32V006/5"></a>
            <a class="unit70 unit70_32V007"  href="https://www.wch.cn/products/CH32V007.html" target="_blank" title="CH32V007"></a>
            <a class="unit70 unit70_32V103"  href="https://www.wch.cn/products/CH32V103.html" target="_blank" title="CH32V103"></a>
            <a class="unit70 unit70_32V203"  href="https://www.wch.cn/products/CH32V203.html" target="_blank" title="CH32V203"></a>
            <a class="unit70 unit70_32V208"  href="https://www.wch.cn/products/CH32V208.html" target="_blank" title="CH32V208"></a>
            <a class="unit70 unit70_32V303"  href="https://www.wch.cn/products/CH32V303.html" target="_blank" title="CH32V303"></a>
            <a class="unit70 unit70_32V307"  href="https://www.wch.cn/products/CH32V307.html" target="_blank" title="CH32V307/5"></a>
            <a class="unit70 unit70_32V317"  href="https://www.wch.cn/products/CH32V317.html" target="_blank" title="CH32V317"></a>
        </div>
        <!-- 青稞内核 -->
        <!-- <div class="imgContent" style="width:100%; height:440px;"  v-else-if="categoryId==77">
            <img style="width:150%; height:440px;" src="@/assets/image/lineUp/77.png" alt="">
        </div>
        -->
        <!-- 8位微控制器系列 -->
        <div class="imgContent" v-else-if="categoryId==75">
            <img style="width:100%; height:100%;" src="@/assets/image/lineUp/75.png" alt="">
            
              <a class="unit75 unit75_32M007"  href="https://www.wch.cn/products/CH32M007.html" target="_blank"  @mouseenter="unit75Mouseover('unit75_childImg_32M007')"  @mouseleave="unit75Mouseleave('unit75_childImg_32M007')" >
                <img class="unit75_childImg unit75_childImg_32M007" @click="clickUnit75_childImg('CH32M007.html')" src="@/assets/image/lineUp/75_childs/32M007.png" alt="">
              </a>
              <a class="unit75 unit75_32M030"  href="https://www.wch.cn/products/CH32M030.html" target="_blank"  @mouseenter="unit75Mouseover('unit75_childImg_32M030')"  @mouseleave="unit75Mouseleave('unit75_childImg_32M030')">
                <img class="unit75_childImg unit75_childImg_32M030" @click="clickUnit75_childImg('CH32M030.html')" src="@/assets/image/lineUp/75_childs/32M030.png" alt="">
              </a>
              <a class="unit75 unit75_32X035"  href="https://www.wch.cn/products/CH32X035.html" target="_blank"  @mouseenter="unit75Mouseover('unit75_childImg_32X035')"  @mouseleave="unit75Mouseleave('unit75_childImg_32X035')">
                <img class="unit75_childImg unit75_childImg_32X035" @click="clickUnit75_childImg('CH32X035.html')" src="@/assets/image/lineUp/75_childs/32X035.png" alt="">
              </a>
              <a class="unit75 unit75_32L103"  href="https://www.wch.cn/products/CH32L103.html" target="_blank"  @mouseenter="unit75Mouseover('unit75_childImg_32L103')"  @mouseleave="unit75Mouseleave('unit75_childImg_32L103')">
                <img class="unit75_childImg unit75_childImg_32L103" @click="clickUnit75_childImg('CH32L103.html')" src="@/assets/image/lineUp/75_childs/32L103.png" alt="">
              </a>
              <a class="unit75 unit75_32V305" href="https://www.wch.cn/products/CH32V307.html"  target="_blank"  @mouseenter="unit75Mouseover('unit75_childImg_32V305')"  @mouseleave="unit75Mouseleave('unit75_childImg_32V305')">
                <img class="unit75_childImg unit75_childImg_32V305" @click="clickUnit75_childImg('CH32V307.html')" src="@/assets/image/lineUp/75_childs/32V305.png" alt="">
              </a>
              <a class="unit75 unit75_641"  href="https://www.wch.cn/products/CH641.html" target="_blank"  @mouseenter="unit75Mouseover('unit75_childImg_641')"  @mouseleave="unit75Mouseleave('unit75_childImg_641')">
                <img class="unit75_childImg unit75_childImg_641" @click="clickUnit75_childImg('CH641.html')" src="@/assets/image/lineUp/75_childs/641.png" alt="">
              </a>
              <a class="unit75 unit75_643"  href="https://www.wch.cn/products/CH643.html" target="_blank"  @mouseenter="unit75Mouseover('unit75_childImg_643')"  @mouseleave="unit75Mouseleave('unit75_childImg_643')">
                <img class="unit75_childImg unit75_childImg_643" @click="clickUnit75_childImg('CH643.html')" src="@/assets/image/lineUp/75_childs/643.png" alt="">
              </a>
              <a class="unit75 unit75_564"  href="https://www.wch.cn/products/CH564.html" target="_blank"  @mouseenter="unit75Mouseover('unit75_childImg_564')"  @mouseleave="unit75Mouseleave('unit75_childImg_564')">
                <img class="unit75_childImg unit75_childImg_564" @click="clickUnit75_childImg('CH564.html')" src="@/assets/image/lineUp/75_childs/564.png" alt="">
              </a>
              <a class="unit75 unit75_645"  href="https://www.wch.cn/products/CH645.html" target="_blank"  @mouseenter="unit75Mouseover('unit75_childImg_645')"  @mouseleave="unit75Mouseleave('unit75_childImg_645')"> 
                <img class="unit75_childImg unit75_childImg_645" @click="clickUnit75_childImg('CH645.html')" src="@/assets/image/lineUp/75_childs/645.png" alt="">
              </a>
              <a class="unit75 unit75_569"  href="https://www.wch.cn/products/CH569.html" target="_blank"  @mouseenter="unit75Mouseover('unit75_childImg_569')"  @mouseleave="unit75Mouseleave('unit75_childImg_569')"> 
                <img class="unit75_childImg unit75_childImg_569" @click="clickUnit75_childImg('CH569.html')" src="@/assets/image/lineUp/75_childs/569.png" alt="">
              </a>
              <a class="unit75 unit75_32V317"  href="https://www.wch.cn/products/CH32V317.html" target="_blank"  @mouseenter.stop="unit75Mouseover('unit75_childImg_32V317')"  @mouseleave.stop="unit75Mouseleave('unit75_childImg_32V317')">
                <img class="unit75_childImg unit75_childImg_32V317" @click="clickUnit75_childImg('CH32V317.html')" src="@/assets/image/lineUp/75_childs/32V317.png" alt="">
              </a>
        </div>
    </div>
</template>
<script>
export default {
  props:['categoryId',],
  name: "lineUp",//以太网芯片
  data() {
    return {
      smallScreen:false,
      windowWidth:window.innerWidth,
    };
  }, 
  computed: {
  },
  created() {
    if (window.innerWidth <= 1000) {
      this.smallScreen = true;
    } else{
      this.smallScreen = false;
    }
    window.addEventListener('resize', this.handleResize)
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize)
  },
  watch: {
    windowWidth(newWidth) {
      if (newWidth <= 1000) {
        this.smallScreen = true;
      }else{
        this.smallScreen = false;
      }
    }
  },
  mounted() {
  },
  activated(){
    console.log('props.categoryId',this.categoryId)
  },
  methods:{
    handleResize() {
      this.windowWidth = window.innerWidth
    },
    unit75Mouseover(className){
      // debugger
      console.log('document.getElementsByClassName',document.getElementsByClassName('unit75_childImg_32V317')[0])
      document.getElementsByClassName(className)[0].style.display = 'block'
    },
    unit75Mouseleave(className){
      document.getElementsByClassName(className)[0].style.display = 'none'
    },
    clickUnit75_childImg(chip){
      console.log('chip===',chip)
      if(chip!==null){
        window.open('https://www.wch.cn/products/'+chip)
      }else{
        return
      }

    },
  }
};
</script>
<style scoped>
  .imgContent{
    width: 100%;
    height: 100%;
    position: relative;
  }
  .unit4_{
    position: absolute;
    display: block;
    z-index: 6;
    width: 7.2%;
    height: 4.5%;
    /* background: #95959594; */
  }
  .unit4{
    position: absolute;
    display: block;
    z-index: 6;
    width: 7.2%;
    height: 4.5%;
    /* background: #95959594; */
    color: #3062bdeb;
    font-weight: 700;
    font-size: 20px;
    text-align: center;
  }
  .unit4:hover{
    transition: all .4s;
    /* font-size: 22px; */
    /* color: #09a5e1; */
    color: #26448a;
    /* font-weight: bolder; */
  }
 
   @media (min-width: 1000px) {
    .unit4{
      font-size: 10px;
    }
    /* .unit4:hover{
      font-size: 11px;
    } */
  }
  @media (min-width: 1200px) {
    .unit4{
      font-size: 13px;
    }
    /* .unit4:hover{
      font-size: 15px;
    } */
  }
   @media (min-width: 1400px) {
    .unit4{
      font-size: 15px;
    }
    /* .unit4:hover{
      font-size: 17px;
    } */
  }
  @media (min-width: 1600px) {
    .unit4{
      font-size: 17px;
    }
    /* .unit4:hover{
      font-size: 19px;
    } */
  }
   @media (min-width: 1750px) {
    .unit4{
      font-size: 19px;
    }
    /* .unit4:hover{
      font-size: 20px;
    } */
  }
  @media (min-width: 2400px) {
    .unit4{
      font-size: 25px;
    }
    /* .unit4:hover{
      font-size: 27px;
    } */
  }
  @media (min-width: 2800px) {
    .unit4{
      font-size: 28px;
    }
    /* .unit4:hover{
      font-size: 30px;
    } */
  }
   @media (min-width: 3500px) {
    .unit4{
      font-size: 37px;
    }
    /* .unit4:hover{
      font-size: 38px;
    } */
  }
  .unit4_397{
    top: 71.2%;
    left: 88.6%;
  }
  .unit4_395{
    top: 66.7%;
    left: 55%;
    height: 4%;
  }
  .unit4_394{
    top: 71.6%;
    left: 55%;
    height: 4%;
  }
  .unit4_392{
    top: 76.6%;
    left: 55%;
    height: 4%;
  }
  .unit4_9121{
    top: 69.1%;
    left: 71.4%;
    height: 4%;
    width: 8%;
  }
  .unit4_9120{
    top: 74.2%;
    left: 71.4%;
    height: 4%;
    width: 8%;
  }
  .unit4_390{
    top: 71.2%;
    left: 37.8%;
  }
  .unit4_182{
    top: 71.2%;
    left: 20.9%;
  }

  /* 蓝牙芯片 */
  .unit63{
    position: absolute;
    display: block;
    z-index: 6;
    width: 7%;
    height: 7.6%;
    /* background: #48484870; */
  }
  .unit63:hover{
    box-shadow: 7px 5px 10px rgba(46, 46, 46,0.4),0 0 10px rgba(0, 0, 0,0) inset;
    border: transparent;
    transition: all .3s;
  }
  .unit63_585{
    top: 15.8%;
    left: 14.1%;
  }
  .unit63_584{
    top: 26.9%;
    left: 14.1%;
  }
  .unit63_592{
    top: 15.8%;
    left: 24.7%;
  }
  .unit63_591{
    top: 26.9%;
    left: 24.7%;
  }
  .unit63_582{
    top: 46.9%;
    left: 14.1%;
  }
  .unit63_581{
    top: 52.7%;
    left: 14.1%;
  }
  .unit63_573{
    top: 68.8%;
    left: 14.1%;
  }
  .unit63_571{
    top: 79.9%;
    left: 14.1%;
  }
  .unit63_32V208{
    top: 46.9%;
    left: 24.7%;
    width: 8.3% !important;
  }
  .unit63_9142{
    top: 46.9%;
    left: 49%;
    width: 7.5% !important;
  }
  .unit63_9143{
    top: 65%;
    left: 48.9%;
    width: 7.5% !important;
    height: 7.5% !important;
  }
  .unit63_9141{
    top: 73.7%;
    left: 48.9%;
    width: 7.5% !important;
    height: 7.5% !important;
  }
  .unit63_9140{
    top: 82.3%;
    left: 48.9%;
    width: 7.5% !important;
    height: 7.3% !important;
  }
  .unit63_579{
    top: 65%;
    left: 74%;
  }
  .unit63_578{
    top: 73.7%;
    left: 74%;
    height: 7.5% !important;
  }
  .unit63_577{
    top: 82.2%;
    left: 74%;
    height: 7.5% !important;
  }
  .unit63_32F208{
    top: 46.9%;
    left: 83.9%;
    width: 8.8% !important;
  }

  /* Cortext-M通用芯片 */
  .unit71{
    position: absolute;
    display: block;
    z-index: 6;
    width: 6.8%;
    height: 6.6%;
  }
  .unit71:hover{
    box-shadow: 7px 5px 10px rgba(46, 46, 46,0.4),0 0 10px rgba(0, 0, 0,0) inset;
    border: transparent;
    transition: all .3s;
  }

  /* MCU芯片 */
  .unit68{
    position: absolute;
    display: block;
    z-index: 6;
    width: 5.4%;
    height: 4.2%;
    /* background: rgb(0, 0, 0,.3); */
  }
  .unit68:hover{
    box-shadow: 7px 5px 10px rgba(46, 46, 46,0.4),0 0 10px rgba(0, 0, 0,0) inset;
    border: transparent;
    transition: all .3s;
  }
  .unit68_32V003{
    top: 86.6%;
    left: 6.3%;
    width: 5.3%;
    height: 4.1%;
  }
  .unit68_641{
    top: 81.4%;
    left: 6.3%;
    width: 5.3%;
    height: 4.5%;
  }
  .unit68_32M007{
    top: 51%;
    left: 12.9%;
    height: 4.4%;
    width: 5.7%;
  }
  .unit68_32V007{
    top: 57.4%;
    left: 12.9%;
    height: 4.4%;
    width: 5.7%;
  }
  .unit68_32V006{
    top: 63.9%;
    left: 12.9%;
    height: 4.4%;
    width: 5.7%;
  }
  .unit68_32V005{
    top: 73.8%;
    left: 12.9%;
    height: 4.2%;
    width: 5.7%;
  }
  .unit68_32V002{
    top: 83.8%;
    left: 12.9%;
    height: 4.4%;
    width: 5.7%;
  }
  .unit68_573{
    top: 10.5%;
    left: 19.7%;
    height: 4.5%;
  }
  .unit68_571{
    top: 30.7%;
    left: 19.7%;
    height: 4.3%;
  }
  .unit68_32V103{
    top: 54.5%;
    left: 19.7%;
    height: 23.7%;
  }
  .unit68_32M030{
    top: 57.5%;
    left: 26.5%;
    width: 5.6%;
  }
  .unit68_565{
    top: 46.8%;
    left: 19.7%;
    height: 4.4%;
  }
  .unit68_585{
    top: 10.5%;
    left: 33.3%;
    height: 4.4%;
  }
  .unit68_582{
    top: 10.5%;
    left: 40.2%;
    height: 4.4%;
  }
  .unit68_581{
    top: 36.4%;
    left: 33.9%;
    width: 4.8%;
    height: 5.4%;
  }
  .unit68_32V203{
    top: 41.5%;
    left: 47%;
    height: 36.7%;
  }
  .unit68_592{
    top: 10.5%;
    left: 54%;
    height: 4.4%;
  }
  .unit68_591{
    top: 30.7%;
    left: 54%;
  }
  .unit68_32V208{
    top: 41.5%;
    left: 54%;
  }
  .unit68_32L103{
    top: 59.8%;
    left: 54%;
    height: 4.4%;
  }
  .unit68_32X035{
    top: 65.1%;
    left: 54%;
    height: 4.4%;
  }
  .unit68_645{
    top: 49.3%;
    left: 54%;
  }
  .unit68_643{
    top: 54.5%;
    left: 54%;
  }
  .unit68_32V303{
    top: 20.9%;
    left: 60.9%;
    height: 24.9%;
  }
  .unit68_32V317{
    top: 18.1%;
    left: 67.1%;
    height: 4.3%;
  }
  .unit68_32V307{
    top: 23.2%;
    left: 67.1%;
    height: 3.8%;
  }
  .unit68_32V305{
    top: 41.5%;
    left: 67.2%;
    width: 5.4%;
    height: 4.3%;
  }
  .unit68_564{
    top: 30.7%;
    left: 74.2%;
    width: 4.9%;
    height: 4.4%;
  }
  .unit68_579{
    top: 20.8%;
    left: 80.8%;
  }
  .unit68_578{
    top: 30.8%;
    left: 80.8%;
  }
  .unit68_577{
    top: 41.5%;
    left: 80.8%;
  }
  .unit68_32F207{
    top: 20.9%;
    left: 87.3%;
  }
  .unit68_32F208{
    top: 38.8%;
    left: 87.3%;
  }
  .unit68_32F205{
    top: 43.9%;
    left: 87.3%;
  }
  .unit68_32F103{
    top: 54.4%;
    left: 87.3%;
    height: 23.8%;
  }
  .unit68_32F203{
    top: 20.7%;
    left: 93.5%;
    height: 57.5%;
  }
  


  /* 青稞RISC-V通用系列 */
  .unit70{
    position: absolute;
    display: block;
    z-index: 6;
    width: 7.4%;
    height: 35%;
    border-radius: 8px;
    /* background: #8181819c; */
  }
  .unit70:hover{
    box-shadow: 7px 5px 10px rgba(108, 108, 108,0.3),0 0 10px rgba(0, 0, 0,0) inset;
    border: transparent;
    transition: all .3s;
  }
  .unit70_32V003{
    top: 66.4%;
    left: 8.7%;
    height: 16.5%;
  }
  .unit70_32V002{
    top: 66.1%;
    left: 17.6%;
    height: 16.4%;
  }
  .unit70_32V006{
    top: 66.1%;
    left: 26.7%;
    height: 16.4%;
  }
  .unit70_32V007{
    top: 66.1%;
    left: 35.5%;
    height: 16.4%;
  }
  .unit70_32V103{
    top: 47.8%;
    left: 44.6%;
  }
  .unit70_32V203{
    top: 47.8%;
    left: 53.8%;
  }
  .unit70_32V208{
    top: 11.1%;
    left: 62.8%;
    height: 71%;
  }
 
  .unit70_32V303{
    top: 47.8%;
    left: 72%;
  }
  .unit70_32V307{
    top: 29.6%;
    left: 81.5%;
    height: 53.2%;
  }
  .unit70_32V317{
    top: 29.1%;
    left: 90.7%;
    height: 53.2%;
  }

  /* 8位微控制器系列 */
  .unit75{
    position: absolute;
    display: block;
    z-index: 6;
    width: 14.7%;
    height: 31.2%;
    /* background: #8181819c; */
  }
  .unit75:hover{
    /* box-shadow: 7px 5px 10px rgba(108, 108, 108,0.3),0 0 10px rgba(0, 0, 0,0) inset; */
    border: transparent;
    transition: all .3s;
  }
  .unit75_childImg{
    width: 134%;
    /* width: 18%; */
    position: absolute;
    cursor: pointer;
    z-index: 8;
  }
  .unit75_32M007{
    top: 25%;
    left: 1.1%;
  }
  .unit75_childImg_32M007{
    top: -22%;
    left: -17%;
    display: none;
    transition: all 0.1s;
  } 
  .unit75_32M030{
    top: 25%;
    left: 17.7%;
  }
  .unit75_childImg_32M030{
    top: -22%;
    left: -17%;
    display: none;
    transition: all 0.1s;
  }
  .unit75_32X035{
    top: 25%;
    left: 34.4%;
  }
  .unit75_childImg_32X035{
    top: -22%;
    left: -17%;
    display: none;
    transition: all 0.1s;
  }
  .unit75_32L103{
    top: 25%;
    left: 51%;
  }
  .unit75_childImg_32L103{
    top: -22%;
    left: -17%;
    display: none;
    transition: all 0.1s;
  }
  .unit75_32V305{
    top: 25%;
    left: 67.7%;
  }
  .unit75_childImg_32V305{
    top: -22%;
    left: -17%;
    display: none;
    transition: all 0.1s;
  }
  .unit75_641{
    top: 60.8%;
    left: 1.1%;
  }
  .unit75_childImg_641{
    top: -22%;
    left: -17%;
    display: none;
    transition: all 0.1s;
  }
  .unit75_643{
    top: 60.8%;
    left: 17.7%;
  }
  .unit75_childImg_643{
    top: -22%;
    left: -17%;
    display: none;
    transition: all 0.1s;
  }
  .unit75_564{
    top: 60.8%;
    left: 34.4%;
  }
  .unit75_childImg_564{
    top: -22%;
    left: -17%;
    display: none;
    transition: all 0.1s;
  }
  .unit75_645{
    top: 60.8%;
    left: 51%;
  }
  .unit75_childImg_645{
    top: -22%;
    left: -17%;
    display: none;
    transition: all 0.1s;
    /* top: 52%;
    left: 49.5%; */
  }
  .unit75_569{
    top: 60.8%;
    left: 67.7%;
  }
  .unit75_childImg_569{
    top: -22%;
    left: -17%;
    display: none;
    transition: all 0.1s;
    /* top: 52%;
    left: 66%; */
  }
  .unit75_32V317{                        
    top: 60.8%;
    left: 84.4%;
  }
  .unit75_childImg_32V317{
    top: -22%;
    left: -17%;
    display: none;
    transition: all 0.1s;
    /* top: 52%;
    left: 82%; */
  }
</style>